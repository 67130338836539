import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"

import LegalCover from "../components/legal/legal-cover.js"
const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact Us - Credit 101" keywords={[`credit`, `card`, `score`, `build`]} description="Credit 101 and its owner can be reached by email."/>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <LegalCover title="Contact Us"></LegalCover>
      <div className="legal-content p-4">
        <h2 className="text-center">
          Email
        </h2>
        <p>Credit 101 and its owner can be reached by email. </p>
        <p><span>For ad sales and media / partnership&nbsp;inquiries, contact <a className="underline" href="mailto:credit101.net@gmail.com">credit101.net@gmail.com</a>.</span></p>
        <p><span>For information about how to contribute to credit101.net, contact <a className="underline" href="mailto:credit101.net@gmail.com"><span>credit101.net@gmail.com</span></a>.</span></p>
        <p>For press and media inquiries please contact&nbsp;<a className="underline" href="mailto:credit101.net@gmail.com">credit101.net@gmail.com</a>.</p>
        <p><span>To send us tips, email <span><a className="underline" href="mailto:credit101.net@gmail.com">credit101.net@gmail.com</a>.</span></span></p>
      </div>
    </Layout>
  )
}

export default Contact
